<template>
  <div id="home" class="home">
    <div :id="productOffersMainWidgetId" />
    <div :id="productPopularMainWidgetId" />
    <div :id="cafeProductsMainWidgetId" />

    <ASmoothReflow>
      <div
        v-if="shouldShowHeroBanner"
        class="hero-banner"
      >
        <template v-if="isBlockReady('hero')">
          <component
            :is="getBlockComponent('hero')"
            :heroes="getBlockData('hero')"
          />
        </template>
        <MHeroBannerSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <ASmoothReflow>
      <div
        v-if="shouldShowHomeInfoBlock"
        class="home-info-block__wrapper"
      >
        <template v-if="isBlockReady('home-info-block')">
          <component
            :is="getBlockComponent('home-info-block')"
            :data="getBlockData('home-info-block')"
          />
        </template>
        <MHomeInfoBlockSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <ASmoothReflow>
      <div
        v-if="shouldShowInterestingCategories"
        class="interesting-categories__wrapper"
      >
        <template v-if="isBlockReady('interesting-categories')">
          <component
            :is="getBlockComponent('interesting-categories')"
            :categories="getBlockData('interesting-categories')"
            :device-info="deviceInfo"
          />
        </template>
        <MInterestingCategoriesSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <!-- <LazyHydrate when-idle>
      <div class="w-100">
        <MHomeOrder v-if="isLoggedIn" />
      </div>
    </LazyHydrate> -->
    <OHomeFavorites
      v-if="isShowFavorites"
      class="home-favorites o-section--slider"
    />

    <ASmoothReflow>
      <div
        v-if="shouldShowBordProducts"
        class="bord-products__wrapper"
      >
        <template v-if="isBlockReady('bord-products')">
          <component
            :is="getBlockComponent('bord-products')"
            :data="getBordProducts"
          />
        </template>
        <MBordProductsSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <ASmoothReflow>
      <div
        v-if="shouldShowSpecialOffers"
        class="special-offers__wrapper"
      >
        <template v-if="isBlockReady('special-offers')">
          <component
            :is="getBlockComponent('special-offers')"
            :offers-main-products="offersMainProducts"
          />
        </template>
        <MBordProductsSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <ASmoothReflow>
      <div
        v-if="shouldShowHomeBrands"
        class="home-brands__wrapper"
      >
        <template v-if="isBlockReady('home-brands')">
          <component
            :is="getBlockComponent('home-brands')"
            :brands-list="getBlockData('home-brands')"
          />
        </template>
        <MBrandsSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <div
      v-if="shouldShowPopularProducts"
      class="popular-products__wrapper"
    >
      <template v-if="isBlockReady('popular-products')">
        <component
          :is="getBlockComponent('popular-products')"
          :popular-main-products="popularMainProducts"
          :popular-categories="popularCategories"
          :popular-products="popularProducts"
          @setActiveCategory="setPopularCategory"
        />
      </template>
      <MBordProductsSkeleton v-else :device-info="deviceInfo" />
    </div>

    <MHomeBanner :title="$t('VARUS in pocket')" :description="$t('Download the mobile app')" />

    <ASmoothReflow>
      <div
        v-if="shouldShowHomeSpecialCategories"
        class="home-special-categories__wrapper"
      >
        <template v-if="isBlockReady('home-special-categories')">
          <component
            :is="getBlockComponent('home-special-categories')"
            :sample-special-categories="getSampleSpecialCategories"
          />
        </template>
        <MBordProductsSkeleton v-else :device-info="deviceInfo" />
      </div>
    </ASmoothReflow>

    <LazyHydrate when-idle>
      <div class="w-100">
        <MRecentlyViewedProducts
          :title-heading="$t('Viewed')"
          :amount="25"
          :show-qty-permanently="true"
        />
      </div>
    </LazyHydrate>

    <div
      v-if="shouldShowHomeCafeCarousel"
      class="home-cafe-carousel__wrapper"
    >
      <template v-if="isBlockReady('home-cafe-carousel')">
        <component
          :is="getBlockComponent('home-cafe-carousel')"
          :products="cafeProductsMain"
          :es-url-param="esUrlParamCafeProductsMainWidget"
        />
      </template>
      <MBordProductsSkeleton v-else :device-info="deviceInfo" />
    </div>

    <div class="center">
      <MCategoryRegion
        class="home__region"
        :label="$t('Label delivery products')"
      />

      <MAdvantages />
      <ASmoothReflow>
        <template v-if="isBlockReady('advantages')">
          <component
            :is="getBlockComponent('advantages')"
          />
        </template>
      </ASmoothReflow>
      <!-- <OSection
        v-if="false"
        :title-heading="$t('Cook with VARUS')"
      >
        <template #link>
          <SfLink
            link="/"
            class="sf-link--primary"
          >
            {{ $t('Read recipes') }}
          </SfLink>
        </template>
        <LazyHydrate when-visible>
          <div class="w-100">
            <MRecipeCarousel />
          </div>
        </LazyHydrate>
      </OSection> -->
      <MSeoBlock v-if="!!getSeoHtml" :seo-html="getSeoHtml" />
    </div>

    <json-ld-home />
  </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, computed } from 'vue';
import LazyHydrate from 'vue-lazy-hydration';
import { Logger } from '@vue-storefront/core/lib/logger';
import { eSputnikEvent } from 'theme/helpers/es';
import MSeoBlock from '../components/molecules/m-seo-block';
// import MHomeOrder from '../components/molecules/m-home-order';
import MAdvantages from 'theme/components/molecules/m-advantages';
// import MRecipeCarousel from '../components/molecules/m-recipe-carousel';
import MRecentlyViewedProducts from '../components/molecules/m-recently-viewed-products';
import MCategoryRegion from 'theme/components/molecules/category/m-category-region.vue';
import MHomeBanner from '../components/molecules/m-home-banner';
import JsonLdHome from 'theme/components/json-ld/json-ld-home';
import { ModalList } from 'theme/store/ui/modals';
import GoogleTagManager from 'theme/mixins/gtm';
import { metaHome } from '../meta/home';
import OHomeFavorites from 'theme/components/organisms/o-home-favorites';
import { HOME_BLOCKS } from '../config/homeBlocks';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import MHeroBannerSkeleton from 'theme/components/molecules/skeletons/m-hero-banner-skeleton.vue';
import MInterestingCategoriesSkeleton from 'theme/components/molecules/skeletons/m-interesting-categories-skeleton.vue';
import MHomeInfoBlockSkeleton from 'theme/components/molecules/skeletons/m-home-info-block-skeleton.vue';
import MBordProductsSkeleton from 'theme/components/molecules/skeletons/m-bord-products-skeleton.vue';
import MBrandsSkeleton from 'theme/components/molecules/skeletons/m-brands-skeleton.vue';
import { useHome } from 'src/composables/pages/useHome';
import { useBlockLoader } from 'src/composables/useBlockLoader';
import { useStoreHelpers } from 'src/composables/useStore';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { useDevice } from 'src/composables/useDevice';
import { isSkipSSR } from 'theme/store/checkout/helpers';

export default defineComponent({
  name: 'Home',
  components: {
    MCategoryRegion,
    JsonLdHome,
    MHomeBanner,
    MRecentlyViewedProducts,
    // MRecipeCarousel,
    // MHomeOrder,
    MAdvantages,
    MSeoBlock,
    LazyHydrate,
    OHomeFavorites,
    ASmoothReflow,
    MHeroBannerSkeleton,
    MInterestingCategoriesSkeleton,
    MHomeInfoBlockSkeleton,
    MBordProductsSkeleton,
    MBrandsSkeleton
  },
  mixins: [GoogleTagManager],
  setup () {
    const { useAction } = useStoreHelpers()
    const {
      // States
      loadManager: homeLoadManager,
      isHeroReady,
      heroData,
      interestingCategoriesReady,
      interestingCategories,
      needToOpenAuth,
      getBrandsList,
      isLoggedIn,
      getBordProducts,
      deviceInfo,
      esUrlParamCafeProductsMainWidget,
      // Computed
      isShowFavorites,
      popularMainProducts,
      popularCategories,
      popularProducts,
      sampleSpecialCategories,
      cafeProductsMain,
      productOffersMainWidgetId,
      productPopularMainWidgetId,
      cafeProductsMainWidgetId,
      offersMainProducts,
      getSeoHtml,
      shippingChangeTrigger,
      getSampleSpecialCategories,
      // Methods
      getNonProductCategoryIdslocal,
      getProductPopularMain,
      getCafeProductsMain,
      formatRegionUrl,
      loadHomeInfoBlock,
      setPopularCategory
    } = useHome()

    const {
      initBlocks,
      initSingleBlock,
      isBlockReady,
      getBlockComponent,
      getBlockData,
      isBlockEmpty,
      hasBlockError,
      getBlockError
    } = useBlockLoader(homeLoadManager)

    const initSpecialOffersBlock = () => {
      initSingleBlock(HOME_BLOCKS['special-offers'])
    }
    const initPopularProductsBlock = () => {
      initSingleBlock(HOME_BLOCKS['popular-products'])
    }
    const shouldShowHeroBanner = computed(() => {
      return !isBlockEmpty('hero') && !hasBlockError('hero')
    })
    const shouldShowHomeInfoBlock = computed(() => {
      return !isBlockEmpty('home-info-block') && !hasBlockError('home-info-block')
    })
    const shouldShowInterestingCategories = computed(() => {
      return !isBlockEmpty('interesting-categories') && !hasBlockError('interesting-categories')
    })
    const shouldShowBordProducts = computed(() => {
      return !isBlockEmpty('bord-products') && !hasBlockError('bord-products')
    })
    const shouldShowSpecialOffers = computed(() => {
      return !isBlockEmpty('special-offers') && !hasBlockError('special-offers')
    })
    const shouldShowPopularProducts = computed(() => {
      return !isBlockEmpty('popular-products') && !hasBlockError('popular-products')
    })
    const shouldShowHomeBrands = computed(() => {
      return !isBlockEmpty('home-brands') && !hasBlockError('home-brands')
    })
    const shouldShowHomeSpecialCategories = computed(() => {
      return !isBlockEmpty('home-special-categories') && !hasBlockError('home-special-categories')
    })
    const shouldShowHomeCafeCarousel = computed(() => {
      return !isBlockEmpty('home-cafe-carousel') && !hasBlockError('home-cafe-carousel')
    })

    onMounted(() => {
      if (needToOpenAuth.value) {
        useAction('ui', 'setNeedToOpenAuth')(false)
        useAction('ui', 'openModal')({ name: ModalList.Auth, payload: 'auth' })
      }
      loadHomeInfoBlock()

      if (!isLoggedIn.value && localStorage.getItem('redirect')) { EventBus.$emit('modal-show', 'modal-signup'); }
      getNonProductCategoryIdslocal()
      eSputnikEvent('MainPage')
      window.addEventListener('popularProductsMainLoaded', initPopularProductsBlock)
      window.addEventListener('cafeProductsMainLoaded', getCafeProductsMain)
      window.addEventListener('productOffersMainLoaded', initSpecialOffersBlock)
      // this.admitadHomePage()
      const blocksToRemove = ['special-offers']
      const filteredBlocks = { ...HOME_BLOCKS }
      blocksToRemove.forEach(key => delete filteredBlocks[key])
      const blocksToLoad = Object.values(filteredBlocks)
      initBlocks(blocksToLoad)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('popularProductsMainLoaded', initPopularProductsBlock)
      window.removeEventListener('cafeProductsMainLoaded', getCafeProductsMain)
      window.removeEventListener('productOffersMainLoaded', initSpecialOffersBlock)
      homeLoadManager.clear()
    })

    return {
      homeLoadManager,
      isHeroReady,
      heroData,
      interestingCategoriesReady,
      interestingCategories,
      isBlockReady,
      getBlockComponent,
      getBlockData,
      esUrlParamCafeProductsMainWidget,
      // States
      getBrandsList,
      deviceInfo,
      // Computed
      popularMainProducts,
      popularCategories,
      isShowFavorites,
      productOffersMainWidgetId,
      productPopularMainWidgetId,
      cafeProductsMainWidgetId,
      offersMainProducts,
      getSeoHtml,
      shippingChangeTrigger,
      isBlockEmpty,
      hasBlockError,
      getBlockError,
      shouldShowHeroBanner,
      shouldShowHomeInfoBlock,
      shouldShowInterestingCategories,
      shouldShowBordProducts,
      shouldShowSpecialOffers,
      shouldShowPopularProducts,
      shouldShowHomeBrands,
      shouldShowHomeSpecialCategories,
      shouldShowHomeCafeCarousel,
      // Getters
      getBordProducts,
      popularProducts,
      sampleSpecialCategories,
      cafeProductsMain,
      getSampleSpecialCategories,
      // Methods
      getNonProductCategoryIdslocal,
      getProductPopularMain,
      getCafeProductsMain,
      formatRegionUrl,
      setPopularCategory
    }
  },
  asyncDataManual: true,
  async asyncData ({ store, context }) {
    if (context?.server?.request?.raw?.headers) {
      // console.log('context?.server?.request?.raw?.headers', context?.server?.request?.raw?.headers)
      const { detectDeviceFromHeaders } = useDevice()
      const serverDeviceInfo = detectDeviceFromHeaders(context?.server?.request?.raw?.headers)

      await store.dispatch('ui/setDeviceInfo', serverDeviceInfo)
    }

    if (isSkipSSR()) return

    Logger.info('Calling asyncData in Home (theme)')();
    if (context) context.output.cacheTags.add(`home`)

    await Promise.all([
      store.dispatch('homepage/loadMainCategory'),
      store.dispatch('promoted/updateVarusCafeBanners'),
      // store.dispatch('homepage/loadSampleProducts', 'specialOffers'),
      // store.dispatch('homepage/loadSampleProductsPopular', true),
      store.dispatch('homepage/loadAdvantagesContent'),
      // store.dispatch('homepage/loadBordProduct'),
      // store.dispatch('brands/fetchBrandsList', {}),
      store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch('promoted/updatePreHeaderBanners')
    ]);

    // if (context) {
    //   const banners = (store.state?.promoted?.headImage || [])

    //   for (const banner of banners) {
    //     context.output.cacheTags.add(`B${banner.id}`)
    //   }
    // }
  },
  metaInfo: metaHome
})
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

#home {
  box-sizing: border-box;
  @include for-desktop {
    padding-top: 20px;
  }

  ::v-deep {
    .m-recently-viewed-products {
      .o-section__content {
        .sf-carousel {
          .glide__slide {
            .sf-product-card {
              max-width: 100%;
              padding-bottom: var(--spacer-20);
            }
          }
        }
      }
    }

    .o-section {
      &--center {
        padding: 0;
      }

      &__heading {
        padding-left: var(--spacer-10);
        @include for-mobile {
          padding-left: var(--spacer-10);
          padding-right: var(--spacer-10);
        }
        @include for-tablet {
          padding-left: var(--spacer-sm);
          padding-right: var(--spacer-sm);
        }
      }

      &__content {
        .sf-carousel {
          .glide__slide {
            flex-shrink: initial;
            padding: 0;

            .sf-product-card {
              max-width: 100%;
              padding-bottom: var(--spacer-20);
            }
          }
        }
        .m-cafe-carousel {
          .glide__slide {
            flex-shrink: 0;
          }
        }
      }
    }
  }

  ::v-deep .m-recently-viewed-products {
    .o-section--center {
      .o-section__content {
        .sf-carousel__wrapper {
          .glide__slides {
            @include for-desktop {
              margin-bottom: var(--spacer-40);
            }
          }
          .sf-product-card {
            @include for-desktop {
              padding-bottom: 0;
            }
            @include for-tablet {
              max-width: px2rem(190);
              width: px2rem(190);
            }

            .sf-product-card__image {

              img {
                @include for-tablet {
                  max-width: px2rem(170);
                  width: px2rem(170);
                  padding: 0;
                }

                @include for-desktop {
                  padding: 0;
                }
              }
            }
          }
        }

        @include for-tablet {
          padding-left: var(--spacer-10);
        }
      }

      @include for-desktop {
        padding: 0 var(--spacer-5);
      }
    }
  }
}

.home {
  &__region {
    margin-top: var(--spacer-50);
  }

  &--special-categories {
    display: none;
    --section-margin: 0;

    &-full {
      display: block;
    }
  }

  ::v-deep .sf-product-card  {
    .sf-price__regular {
      color: var(--black);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-16);
      }
    }
  }

  ::v-deep .all-products-link {
    @include for-tablet {
      font-size: var(--font-size-16);
    }
  }
}

.hero-banner {
  margin: 0 auto 15px;
  box-sizing: border-box;

  @media (min-width: $desktop-min) {
    padding: 0 10px;
    margin: 0 auto 40px;
    max-width: var(--max-width);
  }
}

.interesting-categories__wrapper {
  margin: 0 auto 50px;
  box-sizing: border-box;

  @media (min-width: $desktop-min) {
    margin: 0 auto 55px;
    max-width: var(--max-width);
    padding: 0 10px;
  }
}

.home-info-block__wrapper {
  margin: 0 auto 20px;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    margin: 0 auto 30px;
    max-width: var(--max-width);
    padding: 0 10px;
  }
}
.special-offers__wrapper, .popular-products__wrapper, .home-special-categories__wrapper {
  background: var(--light-gray);
}
.home-cafe-carousel__wrapper {
  background: var(--green-light);
}
.bord-products__wrapper {
  padding: 0 0 50px;
}
.special-offers__wrapper {
  padding: 50px 0;
  --section-padding: 0;

  @media (min-width: $desktop-min) {
    padding: 80px 0 0;
  }
}
.home-brands__wrapper {
  padding: 50px 0;
  --section-padding: 0;

  @media (min-width: $desktop-min) {
    padding: 80px 0;
  }
}
.popular-products__wrapper {
  padding: 50px 0;
  --section-padding: 0;

  @media (min-width: 1024px) {
    padding: 51px 0 10px;
  }
}
.home-special-categories__wrapper {
  padding: 50px 0;
  --section-padding: 0;
}
::v-deep {
  .home-mobile-address {
    margin-top: var(--spacer-40);
    margin-bottom: var(--spacer-20);

    @media (min-width: $desktop-min) {
      display: none;
    }
  }
}
</style>
