import { Priority, Task } from './types';

export class LoadScheduler {
  private queue: Task[] = [];
  private isProcessing = false;

  async schedule (task: Task): Promise<any> {
    if (task.priority === Priority.CRITICAL) {
      return task.execute();
    }

    task.abortController = new AbortController();
    let result: any;

    const wrappedTask = {
      ...task,
      execute: async () => {
        result = await task.execute();
      }
    };

    this.queue.push(wrappedTask);
    this.queue.sort((a, b) => a.priority - b.priority);

    if (!this.isProcessing) {
      this.processQueue();
    }

    return new Promise((resolve, reject) => {
      task.abortController!.signal.addEventListener('abort', () => {
        reject(new Error('Task was aborted'));
      });

      const checkResult = setInterval(() => {
        if (!this.queue.includes(wrappedTask)) {
          clearInterval(checkResult);
          resolve(result);
        }
      }, 100);
    });
  }

  private async processQueue () {
    this.isProcessing = true;

    try {
      while (this.queue.length) {
        const task = this.queue[0];
        await this.yieldToMain();
        await task.execute();
        this.queue.shift();
      }
    } finally {
      this.isProcessing = false;
    }
  }

  private async yieldToMain () {
    if ('scheduler' in window && typeof (window as any).scheduler?.yield === 'function') {
      return (window as any).scheduler.yield();
    }
    return new Promise(resolve => setTimeout(resolve, 0));
  }

  abort (taskId: string) {
    const task = this.queue.find(t => t.id === taskId);
    if (task?.abortController) {
      task.abortController.abort();
    }
  }

  clear () {
    this.queue.forEach(task => {
      if (task.abortController) {
        task.abortController.abort();
      }
    });
    this.queue = [];
  }
}
