import { LoadScheduler } from '../scheduler/LoadScheduler';
import { Priority } from '../scheduler/types';
import Vue from 'vue';

// Додаємо enum для статусу завантаження
export enum LoadStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error'
}

// Розширюємо інтерфейс BlockState
interface BlockState {
  status: LoadStatus,
  ready: boolean,
  component: any,
  data: any,
  error?: Error,
  isEmpty?: boolean // флаг для пустих даних
}

export class LoadManager {
  private scheduler: LoadScheduler;
  private blockStates: Map<string, BlockState>;

  constructor () {
    this.scheduler = new LoadScheduler();
    const reactiveState = Vue.observable({
      states: new Map<string, BlockState>()
    });
    this.blockStates = reactiveState.states;
  }

  async loadBlock (config: {
    id: string,
    priority: Priority,
    loadData: () => Promise<any>,
    loadComponent: () => Promise<any>
  }) {
    // console.log(`[LoadBlock] Starting load for ${config.id}`);

    try {
      await this.scheduler.schedule({
        id: config.id,
        priority: config.priority,
        execute: async () => {
          // console.log(`[LoadBlock] Task started for ${config.id}`);

          // Отримуємо існуючий стан або створюємо новий
          let state = this.blockStates.get(config.id);
          if (!state) {
            state = Vue.observable({
              status: LoadStatus.LOADING,
              ready: false,
              component: null,
              data: null,
              error: null,
              isEmpty: false
            });
            this.blockStates.set(config.id, state);
          }

          // console.log(`[LoadBlock] Using state for ${config.id}:`, state);

          try {
            // console.log(`[LoadBlock] Loading data and component for ${config.id}`);
            const [data, component] = await Promise.all([
              config.loadData().catch(e => {
                state.error = e;
                throw e;
              }),
              config.loadComponent().catch(e => {
                state.error = e;
                throw e;
              })
            ]);
            // console.log(`[LoadBlock] Data and component loaded for ${config.id}`, {
            //   hasData: !!data,
            //   hasComponent: !!component
            // });

            // Оновлюємо існуючий стан
            state.component = component;
            state.data = data;
            state.isEmpty = Array.isArray(data) ? data.length === 0 : !data;
            state.status = LoadStatus.SUCCESS;
            state.ready = true;

            // console.log(`[LoadBlock] State updated for ${config.id}:`, state);

            return { data, component };
          } catch (error) {
            state.status = LoadStatus.ERROR;
            // console.error(`[LoadBlock] Task error for ${config.id}:`, error);
            throw error;
          }
        }
      });

      // console.log(`[LoadBlock] Schedule completed for ${config.id}`);
      return this.blockStates.get(config.id);
    } catch (error) {
      // console.error(`[LoadBlock] Error in loadBlock for ${config.id}:`, error);
      throw error;
    }
  }

  getBlockState (id: string): BlockState | undefined {
    return this.blockStates.get(id);
  }

  abortBlock (id: string) {
    this.scheduler.abort(`${id}-data`);
    this.scheduler.abort(`${id}-component`);
    this.blockStates.delete(id);
  }

  clear () {
    // console.log('=== CLEAR START ===');
    const keys = Array.from(this.blockStates.keys());
    // console.log('Keys to reset:', keys);

    this.scheduler.clear();

    // Скидаємо стани, використовуючи Vue.observable
    keys.forEach(key => {
      // console.log('Resetting state for:', key);
      const state = Vue.observable({
        status: LoadStatus.LOADING,
        ready: false,
        component: null,
        data: null,
        error: null,
        isEmpty: false
      });
      this.blockStates.set(key, state);
    });

    // console.log('=== CLEAR END ===');
  }
}
