export enum Priority {
  CRITICAL = 0,
  IMPORTANT = 1,
  NORMAL = 2,
  LAZY = 3
}

export interface Task {
  id: string,
  priority: Priority,
  execute: () => Promise<any>,
  abortController?: AbortController
}
